.usersBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}
.usersBody {
  padding: 40px 0;
}

.usersTab ul.nav-tabs {
  padding-inline-start: 20px;
  margin-top: 20px;
}

.usersBody span.input-group-text {
  background-color: #fff;
  border: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.usersBody .input-group {
  width: 100%;
}

.usersBody .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.usersBody .form-control:focus svg {
  fill: #000;
}
.UserNameListsTable {
  padding-top: 30px;
}

.adduserBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}
.adduserBody {
  padding: 40px 0;
}

.addUserFormField button {
  margin-top: 30px;
}

.adduserBody .form-control[type="file"]:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.addUserSuccess .modal-body {
  padding: 50px;
}

.addUserSuccess .modal-dialog {
  max-width: 550px;
}

.addUserSuccess .modal-content {
  border-radius: 18px;
}
.addUserPopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.addUserSuccess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #39b54a;
  border: 3px solid #39b54a;
  border-radius: 50%;
  padding: 5px;
}

.deleteUser .modal-body svg {
  width: 50px;
  height: 50px;
}

.deleteUser .modal-body {
  padding: 50px;
}

.deleteUser .modal-dialog {
  max-width: 550px;
}

.btn.btn-user-delete-cancel {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  margin: 10px 15px 0 0;
}

.btn.btn-user-delete-cancel:hover {
  background-color: #39b54a;
  border: 2px solid #39b54a;
}

.btn.btn-user-delete-cancel:focus {
  background-color: #fff;
  border: 2px solid #000;
}

.btn.btn-user-delete {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  background-color: #f15e5e;
  color: #fff;
  border: 2px solid #f15e5e;
  margin: 10px 0 0 0;
}
.btn.btn-user-delete:hover {
  background-color: #39b54a;
  border: 2px solid #39b54a;
}
.btn.btn-user-delete:focus {
  background-color: #f15e5e;
  border: 2px solid #f15e5e;
}
.deleteUserPopTextArea h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.deleteUser .modal-content {
  border-radius: 18px;
}

.deleteUserSuccess .modal-body {
  padding: 50px;
}

.deleteUserSuccess .modal-dialog {
  max-width: 550px;
}

.deleteUserSuccess .modal-content {
  border-radius: 18px;
}
.deleteUserPopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.deleteUserSuccess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #f15e5e;
  border: 3px solid #f15e5e;
  border-radius: 50%;
  padding: 5px;
}

.updateUserBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}
.updateUserBody {
  padding: 40px 0;
}

.viewUserBody {
  padding: 40px 0;
}

.viewUserBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.viewUserDetails {
  margin-top: 30px;
}
.viewUserDetails .btn-edit {
  margin-top: 10px;
}
.viewUserDetails .userProfilePicSection {
  border: none;
}
.ViewUserDetailsData h2 {
  color: #666666;
  font-size: 0.875rem;
  margin: 0;
}
.ViewUserDetailsData p {
  color: #000;
  font-size: 1.125rem;
  margin-bottom: 0;
}
.ViewUserDetailsData {
  margin-bottom: 20px;
}

.userUpdateFormField {
  margin-top: 40px;
}

.userUpdateFormField button {
  margin-top: 30px;
}

.usersTab .tab-content {
  margin-top: 30px;
}

.usersTab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #1c75bc #1c75bc #fff;
  color: #1c75bc;
  font-weight: bold;
}

.usersTab .nav-tabs .nav-link {
  background-color: #f1f1f1;
  color: #000;
  font-weight: bold;
}

.usersTab li {
  padding: 0 5px;
}

.RequestView .modal-body {
  text-align: left;
  padding: 30px 0;
  border-bottom: 1px solid #dee2e6;
}

.RequestView .modal-dialog {
  max-width: 650px;
}

.RequestView .modal-header {
  display: block;
}
.RequestView .modal-content {
  padding: 30px 30px 0 30px;
  border-radius: 18px;
}
.RequestView .modal-header .modal-title {
  font-weight: bold;
}
.RequestViewSections h2 {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}
.RequestViewSections {
  margin-bottom: 20px;
}

.RequestViewSections p {
  margin-bottom: 0;
}
.RequestViewContentArea button {
  margin: 0 5px;
}
.RequestViewSections.Comments {
  border-bottom: 1px solid #dee2e6;
}

.RequestViewSections.Comments p {
  margin-bottom: 30px;
}

.declineUser .modal-body {
  padding: 50px;
}

.declineUser .modal-dialog {
  max-width: 550px;
}

.declineUser .modal-content {
  border-radius: 18px;
}
.declineUserTextArea p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.declineUser .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #f15e5e;
  border: 3px solid #f15e5e;
  border-radius: 50%;
  padding: 5px;
}

.userReportSearch {
  margin-top: 20px !important;
}

.RequestListsTable {
  margin-top: 30px;
}

.addUserFormField .form-control {
  margin-bottom: 0;
}

.addUserFormField .form-select {
  margin-bottom: 0;
}

.userUpdateFormField .form-control {
  margin-bottom: 0;
}

.userUpdateFormField .form-select {
  margin-bottom: 0;
}
