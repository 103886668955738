.names-body h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}
.names-body {
  padding: 40px 0;
}

.ExaminerNameListsTable {
  padding-top: 30px;
}

.names-body span.input-group-text {
  background-color: #fff;
  border: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.names-body .input-group {
  width: 100%;
  margin-top: 20px;
}
.names-body .btn.btn-add {
  margin-top: 20px;
}
.names-body .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.names-body .form-control:focus svg {
  fill: #000;
}
.AddNamesBody {
  padding: 40px 0;
}

.AddNamesBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.ExaminerAddNameFormField {
  margin-top: 20px;
}

.ExaminerAddNameFormField button {
  margin-top: 30px;
}

.ExaminerAddNameFormField .btn-primary:focus {
  box-shadow: none;
}

.ExaminerAddNameFormField .react-datepicker-wrapper input {
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
}

.ExaminerAddNameFormField .react-datepicker-wrapper input:focus-visible {
  outline: none;
}
.ExaminerAddNameFormField .react-datepicker__navigation-icon {
  font-size: 10px;
}

.ExaminerAddNameFormField .form-check-input:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.AddNamesBody .form-control[type="file"]:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.ViewNameBody {
  padding: 40px 0;
}

.ViewNameBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.ViewNameDetails {
  margin-top: 30px;
}
.ViewNameDetailsData h2 {
  color: #666666;
  font-size: 0.875rem;
  margin: 0;
}
.ViewNameDetailsData p {
  color: #000;
  font-size: 1.125rem;
  margin-bottom: 0;
}
.ViewNameDetailsData {
  margin-bottom: 20px;
}

.updateNameBody {
  padding: 40px 0;
}

.updateNameBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.deleteName .modal-body svg {
  width: 50px;
  height: 50px;
}

.deleteName .modal-body {
  padding: 50px;
}

.deleteName .modal-dialog {
  max-width: 550px;
}

.btn.btn-name-delete-cancel {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  margin: 10px 15px 0 0;
}

.btn.btn-name-delete-cancel:hover {
  background-color: #39b54a;
  border: 2px solid #39b54a;
}

.btn.btn-name-delete-cancel:focus {
  background-color: #fff;
  border: 2px solid #000;
}

.btn.btn-name-delete {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  background-color: #f15e5e;
  color: #fff;
  border: 2px solid #f15e5e;
  margin: 10px 0 0 0;
}
.btn.btn-name-delete:hover {
  background-color: #39b54a;
  border: 2px solid #39b54a;
}
.btn.btn-name-delete:focus {
  background-color: #f15e5e;
  border: 2px solid #f15e5e;
}
.deletePopTextArea h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.deleteName .modal-content {
  border-radius: 18px;
}

.deleteNameSuccess .modal-body {
  padding: 50px;
}

.deleteNameSuccess .modal-dialog {
  max-width: 550px;
}

.deleteNameSuccess .modal-content {
  border-radius: 18px;
}
.deletePopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.deleteNameSuccess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #f15e5e;
  border: 3px solid #f15e5e;
  border-radius: 50%;
  padding: 5px;
}

.addNameSuccess .modal-body {
  padding: 50px;
}

.addNameSuccess .modal-dialog {
  max-width: 550px;
}

.addNameSuccess .modal-content {
  border-radius: 18px;
}
.addPopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.addNameSuccess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #39b54a;
  border: 3px solid #39b54a;
  border-radius: 50%;
  padding: 5px;
}

.ExaminerUpdateNameFormField {
  margin-top: 20px;
}

.ExaminerUpdateNameFormField button {
  margin-top: 30px;
}

.ExaminerUpdateNameFormField .form-check-input:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.ExaminerAddNameFormField .form-control {
  margin-bottom: 0;
}

.ExaminerUpdateNameFormField .form-control {
  margin-bottom: 0;
}
p.mobile-error {
  color: red;
}
