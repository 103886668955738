.mediaBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}
.mediaBody {
  padding: 40px 0;
}

.mediaBody span.input-group-text {
  background-color: #fff;
  border: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.mediaBody .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.mediaBody .form-control:focus svg {
  fill: #000;
}

.mediaListSelect {
  margin-top: 20px;
}

.mediaListSelect .form-select {
  height: 40px;
}

.mediaListSelect .form-select:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.mediaListSelect label {
  font-size: 1rem;
  font-weight: bold;
}

.mediaListSelect table {
  border-collapse: inherit;
}

.mediaListSelect table td {
  vertical-align: middle;
}

.mediaListSelect table th {
  font-weight: bold;
}

.mediaListSelect span img {
  width: 30px;
  cursor: pointer;
}

.mediaListSelect td span {
  padding: 0 5px;
}
.mediaListSelect table.nestedTable td {
  padding: 0;
}
img.dummy-img {
  width: 40px;
}

.addMediaFiles {
  margin: 10px 0 30px 0;
}

.addMediaFiles h1 {
  font-size: 1.25rem;
  font-weight: bold;
}

.addMediaFiles .form-select {
  height: 40px;
  margin-bottom: 30px;
}

.addMediaFiles .form-select:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.addMediaFiles label {
  font-size: 1rem;
  font-weight: bold;
}
.addMediaFiles .form-control {
  height: 38px;
  margin-bottom: 20px;
}
.addMediaFiles .form-control:hover {
  box-shadow: none;
  border-color: #ced4da;
}
.addMediaFiles .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.btn.btn-delete-icon {
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
}
.btn.btn-delete-icon svg {
  fill: #000;
}
.btn.btn.btn-delete-icon:hover {
  background-color: #f15e5e;
  border: 1px solid #f15e5e;
}
.btn.btn.btn-delete-icon:focus {
  background-color: #fff;
  border: 1px solid #ccc !important;
}
.btn.btn.btn-delete-icon:hover svg {
  fill: #fff;
}
.btn.btn.btn-delete-icon:focus svg {
  fill: #000;
}
.addMediaFiles .btn.btn-add {
  margin-top: 30px;
}
.addMediaFiles ul {
  padding-inline-start: 0;
  list-style: none;
}
.fieldSetborder {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 20px 15px 0px 15px;
}
.legendtextClass {
  padding: 0.5rem;
  float: none;
  width: auto;
}
legend {
  font-size: 1rem !important;
  font-weight: bold;
}

.addMediaFileForm .form-control {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .addMediaFileForm label.invisible.form-label {
    display: none !important;
  }
}
