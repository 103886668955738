.AdminTestBody {
  padding: 40px 0;
}
.AdminTestBody h1 {
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.AdminTestBody .input-group {
  width: 100%;
  margin-top: 20px;
}
.AdminTestBody span.input-group-text {
  background-color: #fff;
  border: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.AdminTestBody .btn.btn-add {
  margin-top: 20px;
}

.AdminTestBody .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.AdminTestListTable {
  margin-top: 20px;
}
.addTestBody {
  padding: 40px 0;
}
.addTestBody h1 {
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.addTestForm {
  /* overflow-y: scroll;
  height: 600px;
  scrollbar-color: #000 #fff;
  scrollbar-width: auto; */
  margin-top: 20px;
}

.addTestForm::-webkit-scrollbar-thumb {
  background-color: #000 !important;
  border-radius: 10px !important;
  border: 5px solid #ffffff !important;
}
.addTestForm::-webkit-scrollbar {
  width: 16px;
}
.addTestForm::-webkit-scrollbar-track {
  box-shadow: 0px 0 2px grey;
  border-radius: 6px;
}

.addTestForm h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.addTestForm textarea.form-control {
  height: auto;
  margin-bottom: 30px;
}

.btn.test-add-trail {
  background-color: #fff;
  color: #00aeef;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #00aeef;
  box-shadow: none;
  font-size: 1rem;
}

.btn.test-add-trail:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn.test-add-trail:focus {
  background-color: #fff;
  border-color: #00aeef;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn.test-add-level {
  background-color: #fff;
  color: #1c75bc;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
  font-weight: bold;
}

.btn.test-add-level:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn.test-add-level:focus {
  background-color: #fff;
  color: #1c75bc;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc !important;
}

.addTestForm button {
  margin: 0 10px 30px 0;
}

.addTestForm ul {
  list-style: none;
  padding-inline-start: 0;
}
.testQuestionBank h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.testQuestionBank {
  overflow-y: scroll;
  height: 600px;
  scrollbar-color: #000 #fff;
  scrollbar-width: auto;
}
.questionBankArea {
  height: 650px;
}

.testQuestionBank::-webkit-scrollbar-thumb {
  background-color: #000 !important;
  border-radius: 10px !important;
  border: 5px solid #ffffff !important;
}
.testQuestionBank::-webkit-scrollbar {
  width: 16px;
}
.testQuestionBank::-webkit-scrollbar-track {
  box-shadow: 0px 0 2px grey;
  border-radius: 6px;
}

.deleteTest .modal-body svg {
  width: 50px;
  height: 50px;
}

.deleteTest .modal-body {
  padding: 50px;
}

.deleteTest .modal-dialog {
  max-width: 550px;
}

.btn.btn-test-delete-cancel {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
  margin: 10px 15px 0 0;
}

.btn.btn-test-delete-cancel:hover {
  background-color: #39b54a;
  border: 2px solid #39b54a;
}

.btn.btn-test-delete {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: bold;
  background-color: #f15e5e;
  color: #fff;
  border: 2px solid #f15e5e;
  margin: 10px 0 0 0;
}
.btn.btn-test-delete:hover {
  background-color: #39b54a;
  border: 2px solid #39b54a;
}
.btn.btn-test-delete:focus {
  background-color: #f15e5e;
  border: 2px solid #f15e5e;
}
.deletePopUpTest h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.deleteTest .modal-content {
  border-radius: 18px;
}

.deleteTestSucess .modal-body {
  padding: 50px;
}

.deleteTestSucess .modal-dialog {
  max-width: 550px;
}

.deleteTestSucess .modal-content {
  border-radius: 18px;
}
.deletePopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.deleteTestSucess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #f15e5e;
  border: 3px solid #f15e5e;
  border-radius: 50%;
  padding: 5px;
}

.updateTestBody {
  padding: 40px 0;
}

.updateTestBody h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}

.addTestSuccess .modal-body {
  padding: 50px;
}

.addTestSuccess .modal-dialog {
  max-width: 550px;
}

.addTestSuccess .modal-content {
  border-radius: 18px;
}
.addTestPopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.addTestSuccess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #39b54a;
  border: 3px solid #39b54a;
  border-radius: 50%;
  padding: 5px;
}

.questionBankPopup .modal-dialog {
  min-width: 750px;
}

.questionBankArea img {
  width: 100px;
  margin: 0 auto;
  display: block;
}

.questionBankArea {
  padding: 0px 20px 0 0;
}
.questionBankArea .form-check-input {
  border-radius: 0 !important;
}
.questionBankArea .form-check-input:focus {
  box-shadow: none;
}

.questionBankAreaBtn {
  margin-top: 40px;
}

.questionBankPopup .modal-header {
  border: none;
}

.questionBankPopup .btn-close:focus {
  box-shadow: none;
}

.questionBankPopup .modal-header .h4 {
  font-size: 1.5rem !important;
}

.addQuestions .form-label {
  margin-bottom: 0;
}

.addQuestions button {
  margin: 0 0 0 0;
}

.removeLevelS button {
  margin: 0 0 0 0;
}

.removeLevelS .form-label {
  margin-bottom: 0;
}

.btn.remove-test-level {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #707070;
  box-shadow: none;
  font-size: 1rem;
  font-weight: bold;
}

.btn.remove-test-level:hover {
  background-color: #f15e5e;
  border-color: #f15e5e;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn.remove-test-level:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  box-shadow: none;
  border: 2px solid #707070 !important;
}

.addTestForm .form-control {
  margin-bottom: 0;
}
.addTestForm .form-select {
  margin-bottom: 0;
}
.audioOnlyQuestionBankSection {
  padding: 5px;
  border-radius: 6px;
}
.audioOnlyQuestionBankSection svg {
  width: 35px;
  margin-right: 10px;
}
.audioOnyTestStart {
  text-align: left;
}
.audioOnyTestStart .row {
  align-items: center;
}
