form.select-name {
  margin-top: 10px;
}

.TestsBody {
  padding: 40px 0;
}

.TestsBody h1 {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #1c75bc;
}

.TestsBody .ContentMainBody {
  padding: 0;
}

.TestsDetailsBox h3 {
  color: #666666;
  font-size: 0.875rem;
  font-weight: bold;
}

.TestsDetailsBox h2 {
  color: #000;
  font-size: 1.125rem;
  font-weight: bold;
}

.TestsDetailsBox {
  margin-top: 20px;
  padding: 25px 10px 25px 25px;
  border: 2px solid #8da1b2;
  border-radius: 18px;
  display: block;
}

.btn.startTestBtn {
  padding: 3.75rem 5rem;
  background-color: #1a6dd7;
  color: #fff;
  border-radius: 18px;
  font-size: 1.25rem;
  display: block;
  font-weight: bold;
  min-height: 185px;
  width: 100%;
}

.btn.startTestBtn:hover {
  background-color: #39b54a;
  border-color: #39b54a;
}

.TestListsSection {
  margin-top: 10px;
}

.TestsQuestions h1 {
  margin-bottom: 30px;
  color: #000;
  text-transform: none;
}

.TestsQuestions {
  margin-top: 10px;
}

.TestsQuestions img {
  cursor: pointer;
  border: 3px solid #ccc;
}

.TestsQuestions img:hover {
  border: 3px solid #1a6dd7;
}

.TestQuestionsPlayArea {
  margin-top: 0px;
}

.TestQuestionsPlayArea svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.TestQuestionsPlayArea button {
  margin: 0 20px;
}

.mock-test button {
  margin-top: 20px;
  background-color: #00aeef;
  color: #fff;
  border-color: #00aeef;
  border-radius: 30px;
  padding: 0.5rem 1.25rem;
}

.mock-test button:hover {
  background-color: #39b54a;
  border-color: #39b54a;
}

.TrailTestsDetailsBox h3 {
  color: #666666;
  font-size: 0.875rem;
  font-weight: bold;
}

.TrailTestsDetailsBox h2 {
  color: #000;
  font-size: 1.125rem;
  font-weight: bold;
}

.TrailTestsDetailsBox {
  margin-top: 20px;
  padding: 25px 10px 25px 25px;
  border: 2px solid #8da1b2;
  border-radius: 18px;
}

.TrailTestsQuestions h1 {
  margin-bottom: 20px;
  color: #000;
  text-transform: none;
}

.TrailTestsQuestions {
  margin-top: 40px;
}

.TrailTestsQuestions img {
  cursor: pointer;
  margin-bottom: 30px;
  border: 3px solid #ccc;
}

.TrailTestsQuestions img:hover {
  border: 3px solid #1a6dd7;
}

.TrailTestQuestionsPlayArea {
  margin-top: 20px;
}

.TrailTestQuestionsPlayArea svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.TrailTestQuestionsPlayArea button {
  margin: 0 20px;
}

.writeTestScreen .mainContentboxshadow {
  border-radius: 0;
  box-shadow: none;
}

.submitTest .modal-body {
  padding: 50px;
}

.submitTest .modal-dialog {
  max-width: 550px;
}

.submitPopUpTest h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.submitTest .modal-content {
  border-radius: 18px;
}

.submitTest button {
  margin: 0 10px 0px 0;
}

.submitPopUpTest p {
  margin-bottom: 30px !important;
}

.submitTestSucess .modal-body {
  padding: 50px;
}

.submitTestSucess .modal-dialog {
  max-width: 550px;
}

.submitTestSucess .modal-content {
  border-radius: 18px;
}

.submitPopTextAreaOnSuccess p {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.submitTestSucess .modal-body svg {
  width: 50px;
  height: 50px;
  fill: #39b54a;
  border: 3px solid #39b54a;
  border-radius: 50%;
  padding: 5px;
}

.btn.startTestBtn:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.mock-test button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.TestQuestionsPlayArea .btn.btn-submit:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.testsPatientName span {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
}

.testsPatientName span h3 {
  color: #666666;
  font-size: 0.875rem;
  font-weight: bold;
}

.testsPatientName span h2 {
  color: #000;
  font-size: 1.125rem;
  font-weight: bold;
}

.row.testsTopArea {
  align-items: center;
}

.testsTopArea h1 {
  margin-bottom: 0;
}

.TestsPage.ContentMainBody {
  padding: 20px 0 40px 0;
}

.speechTestPlayBtn span {
  font-weight: bold;
  padding-right: 10px;
  vertical-align: middle;
}

.SpeechTrailTest svg {
  width: 50px;
  height: 50px;
}

.SpeechTest svg {
  width: 50px;
  height: 50px;
}

.SpeechTest {
  margin-top: 20px;
}

.SpeechTrailTest {
  margin-top: 20px;
}

.speechTestQuestionsSubmit {
  margin-top: 40px;
}

.speechTestQuestionsSubmit .btn.btn-submit:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.speechTestQuestionsSubmit button {
  margin: 0 20px;
}

.onGoingTestName h3 {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}

.onGoingTestName h2 {
  color: #000;
  font-size: 1.125rem;
  font-weight: bold;
}

.audio-test-invalid-select-error {
  border-color: #dc3545 !important;
  margin-bottom: 10px !important;
}

.audio-test-invalid-select {
  width: 100%;
  font-size: 0.875em;
  color: #dc3545;
}

@media (min-width: 992px) {
  .TestsBody h1 {
    text-align: center;
  }
}

@media (min-width: 1400px) and (max-width: 1480px) {
  .speechTestPlayBtn audio {
    width: 250px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .speechTestPlayBtn audio {
    width: 250px;
  }
}

@media (min-width: 881px) and (max-width: 991px) {
  .speechTestPlayBtn audio {
    width: 220px;
  }
}

@media (min-width: 768px) and (max-width: 880px) {
  .speechTestPlayBtn audio {
    width: 180px;
  }
}