.registrationBackground {
  background-image: url("./images/background-wave.png");
  background-color: #8da1b2;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow-y: scroll;
}
.registrationFormBox {
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
  margin: 25px 0;
  height: 95%;
}
.registrationFormBox img.spetraRegisterLogo {
  margin: 0 auto;
  display: block;
}
.resgiterFormTitle h1 {
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
}
.registrationForm label {
  font-size: 1rem;
  font-weight: bold;
}
.registrationForm .form-control {
  margin-bottom: 0px;
  height: 40px;
}
.registrationForm .form-select {
  height: 40px;
  margin-bottom: 0px;
}

.registrationForm button {
  margin-top: 30px;
}

.registrationForm .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.registrationForm .form-select:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.registrationForm .btn-primary:focus {
  box-shadow: none;
}

.registrationForm textarea.form-control {
  height: auto;
}
.registrationForm textarea {
  resize: none;
}
.registrationForm .btn.btn-cancel:focus {
  background-color: #fff;
  outline: none;
  box-shadow: none;
  color: #000;
  border: 1px solid #ccc !important;
}

.registrationForm .btn.btn-submit:focus {
  background-color: #0d6efd !important;
}

.registrationForm label.terms {
  padding-left: 10px;
  font-size: 0.875rem;
  font-weight: normal;
}

.registrationForm label.terms span {
  cursor: pointer;
  text-decoration: underline;
}
.registrationForm .form-check-input {
  border: 2px solid #ced4da;
}
.registrationForm .form-check-input:focus {
  box-shadow: none;
  border: 2px solid #ced4da;
}

.registrationForm .form-check-input:checked {
  background-color: #198754;
}
.payment-message-wrapper {
  background-color: #f8f9fa; /* Light background for emphasis */
  border-radius: 8px; /* Rounded corners */
  padding: 0; /* Padding around the text */
  margin: 0; /* Margin around the wrapper */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.payment-message {
  color: #333; /* Darker text color for better readability */
  text-align: center; /* Center align the text */
  font-size: 1rem; /* Adjust font size as needed */
}

