.resetFormTitle h1 {
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0 10px 0;
  font-weight: bold;
}
.passwordResetBackground {
  background-image: url("./images/background-wave.png");
  background-color: #8da1b2;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow-y: scroll;
}

.passwordResetBox {
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
}

.passwordResetBox img.spetraResetLogo {
  margin: 0 auto;
  display: block;
}
.backtoLogin p {
  margin-top: 20px;
}
.backtoLogin p span {
  font-weight: bold;
  cursor: pointer;
}
.backtoLogin p span:hover {
  text-decoration: underline;
}

.resetForm .form-control {
  margin-bottom: 20px;
  height: 40px;
}
.resetForm .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.codeVerifiyBackground {
  background-image: url("./images/background-wave.png");
  background-color: #8da1b2;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow-y: scroll;
}

.codeVerifiyBox {
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 650px;
}

.codeVerifiyBox img.spetraResetLogo {
  margin: 0 auto;
  display: block;
}

.verficationCodeTitle h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.newpasswordTitle h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.newPasswordBackground {
  background-image: url("./images/background-wave.png");
  background-color: #8da1b2;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow-y: scroll;
}

.newPasswordBox {
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 650px;
}

.newPasswordBox img.spetraResetLogo {
  margin: 0 auto;
  display: block;
}
