.loginBackground {
  background-image: url("./images/loginPageBg.jpg");
  background-color: #8da1b2;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-y: scroll;
  background-size: cover;
}
.loginFormBox {
  padding: 40px;
  background-color: #00aeef;
  width: 100%;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  border-radius: 6px;
}
.loginFormBox img.spetraLoginLogo {
  margin: 0 auto;
  display: block;
}
.formTitle {
  text-align: center;
  color: #fff;
  margin-bottom: 15px;
}
.formTitle h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
}
.formTitle p {
  font-size: 2rem;
  margin-bottom: 0;
  letter-spacing: 3px;
}
/* .loginFormBox form {
  text-align: center;
} */
.loginFormBox form input {
  border: none;
  border-radius: 4px;
  height: 35px;
  font-size: 0.875rem;
  width: 100%;
  margin-bottom: 0px;
}
.signInButton button {
  background-color: #1c75bc;
  color: #fff;
  padding: 8px 45px;
  border-radius: 18px;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  width: 100%;
  margin-top: 10px;
}
.signInButton button:hover {
  background-color: #fff;
  color: #1c75bc;
}
.signInButton button:focus-visible {
  outline: none !important;
}
.loginFormBox form input:focus-visible {
  outline: none;
}
.loginForm label {
  display: block;
  color: #fff;
  font-size: 0.875rem !important;
  margin-bottom: 0;
  font-weight: normal !important;
}
.LandingPageBody {
  padding-top: 40px;
}
/* .loginForm .user-icon {
  position: absolute;
}
.loginInputIcon {
  display: inline-block;
  position: relative;
}
.loginInputIcon img {
  position: absolute;
  left: 0;
  top: 8px;
  padding-left: 5px;
} */

.forgotPassWord p {
  font-size: 0.75rem;
  margin-bottom: 4px;
  margin-top: 15px;
  color: #fff;
  text-decoration: underline;
  margin-bottom: 10px;
}

.forgotPassWord p span {
  font-weight: bold;
  cursor: pointer;
}

.forgotPassWord p span:hover {
  text-decoration: underline;
}

.registerNow p {
  font-size: 0.8rem;
  margin-bottom: 0px;
  color: #fff;
}

.registerNow p span {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.registerNow p span:hover {
  text-decoration: underline;
}
.loginBackground ul {
  padding-inline-start: 40px;
  list-style: none;
  color: #fff;
}
.masterTitle h1 {
  color: #fff;
  font-size: 4rem;
  line-height: 110%;
}
.masterTitle h1 span {
  color: #00aeef;
  font-size: 4.125rem;
  font-weight: bold;
}
.loginFormBox form input[type="checkbox"] {
  height: auto;
  width: auto;
  border: 2px solid #fff;
  vertical-align: sub;
}
label.checkbox {
  font-size: 0.625rem;
  color: #fff;
  display: inline;
}
ul.landingPageNav li span {
  letter-spacing: 3px;
}
ul.landingPageNav li span strong {
  letter-spacing: 0px;
  font-size: 1rem;
}
ul.landingPageNav li b {
  float: left !important;
  margin-right: 10px;
}
ul.landingPageNav li span {
  width: 85% !important;
}
ul.landingPageNav li {
  float: left !important;
  width: 100% !important;
}
.loginBackground a.nav-link {
  color: #fff !important;
}
.loginBackground a.nav-link img {
  margin-right: 10px;
  vertical-align: bottom;
}
.loginForm .invalid-feedback {
  color: #fff !important;
}
.loginFormBox .form-label span {
  color: #fff !important;
}
.aboutContent .modal-body {
  padding: 20px;
}
.aboutContent p + p {
  margin-top: 0;
}
.aboutContent p {
  margin-bottom: 10px;
}
.aboutContent .modal-dialog {
  max-width: 650px;
}
.aboutContent .modal-header {
  justify-content: center;
  padding: 10px 0;
}
.aboutContent .modal-title {
  font-weight: bold;
}
.aboutMenu a:hover {
  text-decoration: none;
}
.aboutMenu a:focus {
  text-decoration: none;
}
.AboutContentSection {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .loginFormBox {
    width: calc(100% - 30px);
  }
  .loginFormBox form input {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .masterTitle {
    min-height: 400px;
    display: grid;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .LandingPageBody .masterTitle {
    text-align: center;
    margin-bottom: 30px;
  }
}
