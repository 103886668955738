.leftSidebar {
  position: absolute;
  min-width: 250px;
  max-width: 250px;
  z-index: 100;
  left: -250px;
  top: 20px;
  overflow: auto;
}
.leftSidebar.open {
  left: 0;
  transition: left 0.3s ease-in-out;
  background-color: #ebf2f8;
}
img.leftSidebarLogo {
  display: block;
  margin: 0 auto;
  width: 150px;
}
img.userAvatar {
  display: block;
  margin: 0 auto;
  width: 85px;
}
.userDetails h3 {
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
}
.user-date p {
  font-size: 0.75rem;
  margin: 0;
}
.userDate-Time {
  text-align: center;
}
.userDate-Time ul {
  list-style: none;
  padding-inline-start: 0;
  text-align: center;
  display: inline-block;
  border-top: 1px solid #7e7e7e;
  border-bottom: 1px solid #7e7e7e;
}
.userDate-Time li {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 0;
}
.user-date p img {
  width: 25px;
}
.sidenav img.sidenavIcons {
  width: 25%;
}
.sidenav a.nav-link {
  color: #000 !important;
  font-size: 1rem;
  padding: 5px 10px 5px 25px !important;
  width: 90%;
  margin-bottom: 15px;
  border-left: 6px solid #8da1b2;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  background: linear-gradient(to right, #8da1b2 50%, #fff 50%);
  background-position: right;
  background-size: 200% 100%;
  transition: all 0.3s ease;
  background-repeat: no-repeat;
}
.sidenav svg {
  width: 25%;
}
.sidenav a.nav-link.active {
  background-position: left;
  color: #fff !important;
  text-decoration: none !important;
}
.sidenav a.nav-link.active svg {
  fill: #fff !important;
}
.sidenav a.nav-link:hover {
  background-position: left;
  color: #fff !important;
  text-decoration: none !important;
}
.sidenav a.nav-link:focus {
  text-decoration: none !important;
}
.sidenav a.nav-link:hover svg path {
  fill: #fff !important;
}
.logOut {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
.logOut svg {
  width: 30px;
  fill: #000;
}
.logOut button {
  background-color: #fff !important;
  color: #000;
  border-radius: 30px;
  border: 2px solid #707070;
  box-shadow: none;
}
.logOut button:hover {
  background-color: #f1f1f1 !important;
  color: #000;
  border: 2px solid #707070;
}
.logOut button:focus {
  background-color: #fff !important;
  color: #000;
  border: 2px solid #707070 !important;
  box-shadow: none;
}
.logOut button:active {
  background-color: #fff !important;
  color: #000;
  border: 2px solid #707070 !important;
  box-shadow: none;
}

.sideBarTermsMenu p {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 8px;
  display: inline-block;
}

.sideBarTermsMenu p:hover {
  text-decoration: underline;
}

.sideBarTermsMenu p + p {
  margin-top: 0;
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .user-date p {
    font-size: 0.75rem;
  }
  .user-date p img {
    width: 22px;
  }
  .sidenav a.nav-link {
    padding: 5px 10px 5px 15px !important;
  }
}
