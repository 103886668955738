.dashboard-body h1 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #1c75bc;
}
.dashboard-body {
  padding: 40px 0;
}
.dashboard-box svg {
  width: 60px;
  height: 60px;
  background-color: #8da1b2;
  padding: 20px 9px;
  border-radius: 50%;
  fill: #fff;
}
.dashboard-box {
  padding: 25px 0;
  border: 2px solid #8da1b2;
  border-radius: 6px;
}
.dashboard-box p {
  font-size: 1.125rem;
}
.dashboard-section1 {
  padding: 20px 0;
}
.dashboard-section1 .dashboard-box p {
  margin-bottom: 0;
}
.dashboard-box h2 {
  font-size: 1.25rem;
  font-weight: bold;
}
.dashboard-section2 {
  padding: 10px 0 20px 0;
}
h2.dashboard-title {
  padding-bottom: 10px;
  border-bottom: 2px solid #8da1b2;
}
.dashboard-section3 {
  padding: 10px 0 20px 0;
}

.dashboard-section3 .dashboard-box {
  min-height: 250px;
}
.bg-spetra-bg {
  background-color: #8da1b2;
}
.badge.bg-spetra-bg {
  padding: 10px;
  font-size: 1rem;
  padding: 5px 15px;
}

.padding-right-0 {
  padding-right: 0 !important;
}

@media (max-width: 1199.98px) {
  .dashboard-section1 .dashboard-box {
    margin-bottom: 40px;
  }
  .dashboard-section3 .dashboard-box {
    margin-bottom: 40px;
  }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .dashboard-section1 .dashboard-box {
    text-align: center;
    padding: 20px 0;
  }
  .dashboard-section1 .dashboard-box svg {
    margin-bottom: 10px;
  }
}
