.resultsTableList {
  margin-top: 20px;
}

.resultsTableList td span {
  display: block;
  font-size: 0.875rem;
}
.resultsTableList table.nestedTable td {
  padding: 0;
}

.resultsTableList td.results-status-completed span {
  font-size: 1rem;
  background-color: #39b54a;
  color: #fff;
  padding: 10px 30px !important;
  border-radius: 18px;
  display: inline-block;
  font-weight: bold;
}

.resultsTableList td.results-status-incomplete span {
  font-size: 1rem;
  background-color: #fd0;
  color: #000;
  padding: 10px 30px !important;
  border-radius: 18px;
  display: inline-block;
  font-weight: bold;
}

.commentsPopup h3 {
  color: #666666;
  font-size: 0.875rem;
  font-weight: bold;
}

.commentsPopup h2 {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}

.commentsPopup .modal-dialog {
  max-width: 750px;
}
.commentsPopupTitle {
  border-bottom: 1px solid #7e7e7e;
  margin-bottom: 20px;
}

.commentsListArea {
  border-bottom: 1px solid #7e7e7e;
  padding: 10px 0;
}

.commentsListArea h2 {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}

.commentsListArea h3 {
  color: #000;
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 0;
}

table .commentIcon span svg {
  border: none;
  border-radius: 0;
  margin: 0;
  fill: #7e7e7e;
  height: 30px;
  width: 30px;
}

table .commentIcon span svg:hover {
  background-color: #fff;
  fill: #1c75bc;
}

.modal.commentsPopup .modal-body {
  padding: 20px;
}
