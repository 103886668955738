html {
  overflow-x: hidden;
  min-height: 100%;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1rem !important;
  color: #000 !important;
  min-height: 100%;
}
.spetraLayout {
  background-color: #ebf2f8;
}
.spetraLayout > .container-fluid {
  padding-left: 0;
}
.mainContentboxshadow {
  box-shadow: none;
  background-color: #fff;
  min-height: 100vh;
}
.ContentMainBody {
  padding: 40px 0;
}
.ContentMainBody h1 {
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #1c75bc;
}
.btn-primary:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
.modal-open {
  padding-right: 0 !important;
}

.btn.btn-back {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #707070;
  box-shadow: none;
  font-size: 1rem;
}
.btn.btn-back svg {
  margin-right: 5px;
}

.btn.btn-back:hover {
  background-color: #f1f1f1;
  border-color: #707070;
  color: #000;
  outline: none;
  box-shadow: none;
}

.btn.btn-back:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  box-shadow: none;
  border: 2px solid #707070 !important;
}

.btn.btn-add {
  background-color: #fff;
  color: #1c75bc;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
}

.btn.btn-add:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc;
}
.btn.btn-add:focus {
  background-color: #fff;
  color: #1c75bc;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc !important;
}

.btn.btn-add svg {
  margin-right: 5px;
  width: 0.9rem;
  height: 0.9rem;
}

.btn.btn-cancel {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #707070;
  box-shadow: none;
  font-size: 1rem;
  font-weight: bold;
}

.btn.btn-cancel:hover {
  background-color: #f1f1f1;
  border-color: #707070;
  color: #000;
  outline: none;
  box-shadow: none;
}

.btn.btn-cancel:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  box-shadow: none;
  border: 2px solid #707070 !important;
}

.btn.btn-submit {
  background-color: #1c75bc;
  color: #fff;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
  font-weight: bold;
}
.btn.btn-submit:hover {
  background-color: #fff;
  border-color: #1c75bc;
  color: #1c75bc;
}
.btn.btn-submit:focus {
  background-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc !important;
}

.page-item.active .page-link {
  background-color: #1a6dd7 !important;
  border-color: #1a6dd7 !important;
  color: #fff !important;
}
.pagination .page-link:hover {
  background-color: #1a6dd7 !important;
  border-color: #1a6dd7 !important;
  color: #fff !important;
  border-radius: 50%;
  text-decoration: none;
}
.pagination .page-item.active .page-link {
  border-radius: 50% !important;
  padding: 5px 15px !important;
}
.page-link {
  color: #000 !important;
}
.pagination .page-link {
  border: transparent !important;
  font-size: 1.25rem !important;
  padding: 5px 15px !important;
  border-radius: 50%;
}

.pagination .page-item:first-child .page-link {
  border-radius: 50%;
  border-width: 2px !important;
  font-size: 1.25rem;
}

.pagination .page-item:first-child .page-link:hover {
  border-color: #0d6efd !important;
  text-decoration: none;
}
.pagination .page-item:last-child .page-link:hover {
  border-color: #0d6efd !important;
  text-decoration: none;
}

.pagination a.page-link span {
  font-size: 1.25rem;
  color: #8da1b2;
  font-weight: bold;
}
.pagination a.page-link:hover span {
  color: #fff;
}
.pagination .page-item:last-child .page-link {
  border-radius: 50%;
  font-size: 1.25rem;
}
.pagination .page-item {
  margin: 0 10px;
}

.pagination .page-link:focus {
  box-shadow: none;
}
.page-link:focus {
  background-color: #fff !important;
}
form .form-label {
  font-size: 1rem !important;
  font-weight: bold !important;
}
form .form-label span {
  color: red;
  font-weight: bold;
}
form .form-control:hover {
  box-shadow: none;
  border-color: #ced4da;
}
form .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}
form .form-select:hover {
  box-shadow: none;
  border-color: #ced4da;
}
form .form-select:focus {
  box-shadow: none;
  border-color: #ced4da;
}

form .form-control {
  margin-bottom: 20px;
  height: 40px;
}

form .form-select {
  margin-bottom: 20px;
  height: 40px;
}

form textarea.form-control {
  height: auto;
}

form textarea {
  resize: none;
}

.ProfilePicSection img {
  display: block;
  margin: 0 auto;
}

.btn.btn-submit-tests {
  background-color: #fff;
  color: #333;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  font-weight: bold;
  border: 2px solid #333;
  box-shadow: none;
  font-size: 1rem;
}

.btn.btn-submit-tests.submitted {
  background-color: #39b54a !important;
  border: 2px solid #39b54a !important;
  opacity: 1 !important;
}

.btn.btn-submit-tests:hover {
  background-color: #39b54a;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 2px solid #39b54a !important;
}

.btn.btn-submit-tests:focus {
  background-color: #fff;
  color: #333;
  outline: none;
  box-shadow: none;
  border: 2px solid #333 !important;
}

.btn.btn-submit-tests:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.header-page-search .input-group {
  width: 100%;
}

.header-page-search span.input-group-text {
  background-color: #fff;
  border: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.header-page-search .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.spetraHelp h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.userManual {
  margin-top: 20px;
}

.HelpAccordionArea {
  margin-top: 20px;
}

.userManual p {
  margin-bottom: 0;
}

.HelpAccordionArea h2.accordion-header button {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}
.HelpAccordionArea .accordion-item {
  border: 1px solid #8da1b2;
  padding: 15px 30px;
  margin-bottom: 30px;
  border-radius: 6px;
}
.HelpAccordionArea .accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: none;
}

.HelpAccordionArea .accordion-button {
  padding: 0;
}

.HelpAccordionArea .accordion-body {
  padding: 0;
  margin-top: 15px;
}

.HelpAccordionArea .accordion-item:not(:first-of-type) {
  border-top: 1px solid #8da1b2;
}

.HelpAccordionArea .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
p.marginBottomTen {
  margin-bottom: 10px;
}

.btn.btn-edit {
  background-color: #fff;
  color: #1c75bc;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  font-weight: bold;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
}

.btn.btn-edit:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn.btn-edit:focus {
  background-color: #fff;
  color: #1c75bc;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc !important;
}

.table > thead {
  background-color: #ebf2f8;
}

.table > :not(:first-child) {
  border-top: none !important;
}

table {
  border-collapse: collapse !important;
  text-align: left !important;
}

table td {
  padding: 15px 10px !important;
  vertical-align: middle !important;
}

table th {
  font-weight: bold !important;
}

td span {
  padding: 0 5px !important;
}
table.nestedTable td {
  padding: 0 !important;
}

table td span img {
  width: 30px;
  cursor: pointer;
}

.btn.btn-edit svg {
  margin-right: 10px;
  vertical-align: initial;
}

.view-icon svg {
  fill: #1c75bc;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.view-icon svg:hover {
  background-color: #1c75bc;
  fill: #fff;
}

table span svg {
  background-color: #fff;
  fill: #1c75bc;
  width: 35px;
  height: 35px;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid #1c75bc;
}

table span svg {
  cursor: pointer;
  margin: 5px 0;
}

table span svg:hover {
  background-color: #1c75bc;
  fill: #fff;
  border: 1px solid #1c75bc;
}

table span svg:focus {
  background-color: #fff;
  fill: #1c75bc;
  border: 1px solid #1c75bc;
  outline: none;
}

.btn.btn-report {
  margin: 0 10px;
  background-color: #fff;
  color: #1c75bc;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  font-weight: bold;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
}

.btn.btn-report:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc;
}

.btn.btn-report svg {
  fill: #1c75bc;
  width: 30px;
  height: 30px;
}

.btn.btn-report:hover svg {
  fill: #fff;
}

.btn.btn-report:focus svg {
  fill: #1c75bc;
}

.btn.btn-report:focus {
  background-color: #fff !important;
  border: 2px solid #1c75bc !important;
  color: #1c75bc;
}

.form-select:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.main {
  width: 100% !important;
  padding-right: 0 !important;
}

img.sidebarOpenLogo {
  width: 120px;
}

.toggleMenu svg {
  width: 50px;
  height: 40px;
  cursor: pointer;
  background-color: #1c75bc;
  fill: #fff;
  border-radius: 6px;
}
.toggleMenu {
  margin: 0 15px 0 0;
}

td.tableAction {
  width: 170px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
  border-color: #ced4da !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  box-shadow: none !important;
}

.was-validated .form-select:valid {
  border-color: #ced4da !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: none !important;
}

.btn.btn-clear {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  border: 2px solid #707070;
  box-shadow: none;
  font-size: 1rem;
}

.btn.btn-clear:hover {
  background-color: #f1f1f1;
  border-color: #707070;
  color: #000;
  outline: none;
  box-shadow: none;
}

.btn.btn-clear:focus {
  background-color: #fff;
  color: #000;
  outline: none;
  box-shadow: none;
  border: 2px solid #707070 !important;
}

.row.ContentMainBodyTopArea {
  align-items: center;
}

.ContentMainBodyTopArea h1 {
  margin-bottom: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.modal.TermsModalContent .modal-body {
  padding: 20px;
}
.modal.TermsModalContent .modal-dialog {
  max-width: 750px;
}
.modal.TermsModalContent .modal-header {
  justify-content: center;
  padding: 10px 0;
}
.modal.TermsModalContent .modal-title {
  font-weight: bold;
}

.modal.TermsModalContent p + p {
  margin-top: 0;
}

.modal.TermsModalContent p {
  margin-bottom: 10px;
  font-size: 14px;
}

.modal.TermsModalContent p strong {
  font-size: 16px;
}

.modal.TermsModalContent p:last-child {
  margin-bottom: 0;
}

.modal-body .TermsSection {
  margin-bottom: 20px;
}

.modal.TermsModalContent .modal-title.h4 {
  font-size: 1.25rem;
}

.modal-body .TermsSection .nav-link {
  display: inline;
  padding: 0;
  color: #000 !important;
  text-decoration: underline;
}

.modal-body .TermsSection .nav-link:hover {
  color: #000;
  text-decoration: underline;
}

.modal-body .TermsSection ul li {
  padding-bottom: 5px;
  font-size: 14px;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.modal-body .TermsSection ul {
  margin-bottom: 0;
}

.modal-body .TermsSection ul li:last-child {
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .margin-md-bottom-20 {
    margin-bottom: 20px;
  }
  .margin-md-bottom-40 {
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .margin-lg-top-25 {
    margin-top: 25px !important;
  }
}
