.reportSearch .input-group {
  width: 100%;
}
.reportSearch span.input-group-text {
  background-color: #fff;
  border: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.reportSearch .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.examinerReportTable {
  margin-top: 20px;
}

.viewReportData h2 {
  color: #666666;
  font-size: 0.875rem;
  margin: 0;
}

.viewReportContent {
  margin-top: 20px;
}

.viewReportData p {
  color: #000;
  font-size: 1.125rem;
  margin-bottom: 0;
}
.viewReportData {
  margin-bottom: 20px;
}

.viewReportTable {
  margin-top: 20px;
}

.viewReportTable td span {
  display: block;
  font-size: 0.875rem;
}
.viewReportTable table.nestedTable td {
  padding: 0;
}

.viewReportTable td.results-status-completed span {
  font-size: 1rem;
  background-color: #39b54a;
  color: #fff;
  padding: 10px 30px !important;
  border-radius: 18px;
  display: inline-block;
  font-weight: bold;
}

.viewReportTable td.results-status-incomplete span {
  font-size: 1rem;
  background-color: #fd0;
  color: #000;
  padding: 10px 30px !important;
  border-radius: 18px;
  display: inline-block;
  font-weight: bold;
}

.reportSearch {
  margin: 0px 0 20px 0;
}

.rs-btn-link {
  color: #000 !important;
}
.rs-btn-primary {
  background-color: #1c75bc !important;
}

.reportSearch .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}

.reportSearch .rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
}

.reportSearch .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border: 1px solid #ced4da !important;
}

.reportSearch .rs-picker-toggle-wrapper {
  width: 100%;
}

.reportSearch .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 8px;
  padding-bottom: 9px;
}

.reportSearch ::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

.reportSearch :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.reportSearch ::placeholder {
  color: #000;
}

.reportSearch .rs-picker-toggle-read-only {
  opacity: 1;
}

.downloadReportArea {
  margin-top: 30px;
}

.btn.btn-pdf {
  background-color: #fff;
  color: #1c75bc;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
  margin-right: 10px;
}

.btn.btn-pdf:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc;
}
.btn.btn-pdf:hover svg {
  fill: #fff;
}
.btn.btn-pdf:focus {
  background-color: #fff;
  color: #1c75bc;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc !important;
}

.btn.btn-pdf:focus svg {
  fill: #1c75bc;
}

.btn.btn-pdf:active svg {
  fill: #1c75bc;
}

.btn.btn-pdf svg {
  width: 35px;
  height: 35px;
  fill: #1c75bc;
}

.btn.btn-print {
  background-color: #fff;
  color: #1c75bc;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  border: 2px solid #1c75bc;
  box-shadow: none;
  font-size: 1rem;
}

.btn.btn-print:hover {
  background-color: #1c75bc;
  border-color: #1c75bc;
  color: #fff;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc;
}
.btn.btn-print:focus {
  background-color: #fff;
  color: #1c75bc;
  outline: none;
  box-shadow: none;
  border: 2px solid #1c75bc !important;
}

.btn.btn-print svg {
  width: 35px;
  height: 35px;
  fill: #1c75bc;
}

.btn.btn-print:hover svg {
  fill: #fff;
}

.btn.btn-print:focus svg {
  fill: #1c75bc;
}
.btn.btn-print:active svg {
  fill: #fff;
}

@media (min-width: 768px) and (max-width: 850px) {
  .btn.btn-report {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
  }
}
